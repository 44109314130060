/** @jsx jsx */

import { css, jsx } from '@emotion/react'

import React, { useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'

import { animated, useSpring } from 'react-spring'
import { AiOutlineStar } from 'react-icons/ai'
import { AiFillStar } from 'react-icons/ai'
import Slide from 'react-reveal/Slide'
import Fade from 'react-reveal/Fade'

import { TestimonialType } from '../components/testimonials'

import { globalCss, variables, colors } from '../styles/global'
import Layout from '../components/layout'
import SEO from '../components/seo'

const KlantenVertellen = ({ data }: any) => {
  console.log(data)
  const recensieData = data.recensieData.edges
  const [displayAmt, setDisplayAmt] = useState(0)

  useEffect(() => {
    function handleResize() {
      if (typeof window !== 'undefined') {
        setDisplayAmt(Math.floor((window.innerWidth - 200) / 340))
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  recensieData.forEach((e: any) => {
    if (e.node.frontmatter.pos === null) {
      e.node.frontmatter.pos = 9999
    }
  })
  const sortedRecensieData = recensieData.sort((a: any, b: any) => {
    return a.node.frontmatter.pos - b.node.frontmatter.pos
  })

  return (
    <Layout hideMenu={false} showButton>
      <SEO
        title="Klanten Vertellen"
        description="Lees wat anderen over ons zeggen!"
      />
      <div
        className="container"
        css={css`
          background-color: ${colors.black};
          color: ${colors.white};
          padding: 30px ${variables.HPaddingDesktop}px 40px
            ${variables.HPaddingDesktop}px;
          ${variables.mobile} {
            padding: 30px ${variables.HPaddingMobile}px 0px
              ${variables.HPaddingMobile}px;
          }
        `}
      >
        <h2
          css={css`
            margin: auto;
            margin-top: 60px;
            text-align: center;
          `}
        >
          KLANTEN VERTELLEN
        </h2>
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            margin: auto;
            justify-content: center;
          `}
        >
          <Fade up cascade>
            {sortedRecensieData.map(
              (recensie: TestimonialType, index: number) => {
                return (
                  <div
                    css={css`
                      max-width: 400px;
                    `}
                  >
                    <Testimonial
                      show={true}
                      text={recensieData[index].node.frontmatter.recensie}
                      namen={recensieData[index].node.frontmatter.namen}
                      datum={recensieData[index].node.frontmatter.datum}
                      stars={recensieData[index].node.frontmatter.stars}
                      align="left"
                      maxLength={1000}
                    />
                  </div>
                )
              }
            )}
          </Fade>
        </div>
      </div>
    </Layout>
  )
}

export function Testimonial({
  text,
  namen,
  datum,
  show,
  maxLength,
  stars,
}: TestimonialType) {
  const [readMore, setReadMore] = useState(false)
  const [innerTxt, setInnerTxt] = useState(text)

  useEffect(() => {
    if (text.length > maxLength) {
      let shortTxt = splitAtFirstWhitespaceBeforeIndex(text, maxLength)
      shortTxt += '...'
      setReadMore(true)
      setInnerTxt(shortTxt)
    }
  }, [])

  return (
    <div
      className={show ? 'show' : 'hide'}
      css={css`
        transition: opacity 0.7s;
        background-color: ${colors.black};
        border: 2px solid ${colors.white};
        box-shadow: 0 0 5px ${colors.white};
        border-radius: 5px;
        padding: 5px 20px 5px 20px;
        margin: 40px 0;
        width: 80%;
        color: ${colors.lightBlue};
        position: relative;
        height: fit-content;
      `}
    >
      <p
        css={css`
          font-size: 2rem;
          margin-bottom: 0.3rem;
          color: ${colors.white};
        `}
      >
        <b>{namen}</b>
      </p>
      <p
        css={css`
          margin-top: 0;
          font-size: 1rem;
          color: ${colors.white};
        `}
      >
        {datum}
      </p>{' '}
      <hr
        css={css`
          border: 1px solid ${colors.black};
        `}
      />
      {[...Array(5)].map((star: any, index: number) => {
        if (index < stars) {
          return (
            <AiFillStar key={'star' + index} size="25px" color={colors.pink} />
          )
        } else {
          return (
            <AiOutlineStar
              key={'star' + index}
              size="25px"
              color={colors.pink}
            />
          )
        }
      })}
      <p
        css={css`
          font-size: 1rem;
        `}
      >
        <i>"{innerTxt}"</i>
      </p>
      {readMore && (
        <div
          css={css`
            margin-bottom: 20px;
          `}
        >
          <a href="/klantenvertellen">
            <u>Lees verder...</u>
          </a>
        </div>
      )}
    </div>
  )
}

function splitAtFirstWhitespaceBeforeIndex(string: string, index: number) {
  let splitIndex = 0
  for (let i = index; i > 0; i--) {
    if (string.charAt(i) == ' ') {
      splitIndex = i
      break
    }
  }

  return string.slice(0, splitIndex)
}

export default KlantenVertellen

export const query = graphql`
  query recensieQuery {
    recensieData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "recensie" } } }
    ) {
      edges {
        node {
          frontmatter {
            recensie
            datum
            namen
            stars
            pos
          }
        }
      }
    }
  }
`
